import {
  IBulkSortableItem,
  IBulkSortRequest,
  ICompartmentReference,
  IGetBulkSortableItemsRequest,
  IGetLoadCarrierByReferenceRequest,
  IGetPickBatchSortStateRequest,
  IGetSortResultRequest,
  IMarkPickBatchSortedRequest,
  IMarkSortLoadCarrierSortedRequest,
  IPickBatchSortState,
  IPickLoadCarrierSortResult,
  IPutItemInCompartmentRequest,
  IPutItemInCompartmentResult,
  IWantsNewCompartmentRequest
} from '@graphql/sort/types'
import { ILoadCarrier, IPickBatch, StateEnum } from '@graphql/pick/types'
import { useStore } from '@store/store'
import { soundBoard } from '@services/sound'
import { confirmYesNo } from '@/functions/dialog'
import { nextTick } from 'vue'
import { f7 } from 'framework7-vue'
import { perceptibleToast } from '@services/perceptibleToast'
import { toast } from '@services/toast'
import useProjectSorting from '@composables/useProjectSorting'

export default () => {
  const store = useStore()
  const { addProject, activeItem } = useProjectSorting()

  const getSequenceFor = (loadCarrier: ILoadCarrier) => loadCarrier.sequence ?? loadCarrier.id

  const getLoadCarrierByReference = async (
    request: IGetLoadCarrierByReferenceRequest
  ): Promise<Partial<ILoadCarrier>> => store.dispatch('sort/getLoadCarrierByReference', request)

  const putArticleInSortCompartment = async (
    request: IPutItemInCompartmentRequest
  ): Promise<IPutItemInCompartmentResult> => store.dispatch('sort/putItemInCompartment', request)

  const wantsNewCompartment = async (
    request: IWantsNewCompartmentRequest
  ): Promise<IPutItemInCompartmentResult> => store.dispatch('sort/wantsNewCompartment', request)

  const getSortResult = async (
    request: IGetSortResultRequest
  ): Promise<IPickLoadCarrierSortResult> => store.dispatch('sort/getSortResult', request)

  const getPickBatchSortState = async (
    request: IGetPickBatchSortStateRequest
  ): Promise<IPickBatchSortState> => store.dispatch('sort/getPickBatchSortState', request)

  const markSortLoadCarrierSorted = async (
    request: IMarkSortLoadCarrierSortedRequest
  ): Promise<boolean> => store.dispatch('sort/markSortLoadCarrierSorted', request)

  const markPickBatchSorted = async (request: IMarkPickBatchSortedRequest): Promise<boolean> =>
    store.dispatch('sort/markPickBatchSorted', request)

  const bulkSort = async (request: IBulkSortRequest): Promise<boolean> =>
    store.dispatch('sort/bulkSort', request)

  const getBulkSortableItems = async (
    request: IGetBulkSortableItemsRequest
  ): Promise<IBulkSortableItem[]> => store.dispatch('sort/getBulkSortableItems', request)

  const onResult = async (pickBatch: IPickBatch) => {
    if (pickBatch.state < StateEnum.PICKED && pickBatch.state > StateEnum.PACKED) {
      await perceptibleToast.error(`Pick batch #${pickBatch.id} is not sortable!`, 0)
      return
    }

    if (!activeItem.value) {
      await addProject({ pickBatchId: pickBatch.id })
    }

    try {
      const pickBatchSortState = await getPickBatchSortState({ pickBatchId: pickBatch.id })

      if (pickBatchSortState.lastSortReference.pickLoadCarrierId) {
        await toast
          .success(
            `Continue sort process for load carrier ${pickBatchSortState.lastSortReference.pickLoadCarrierId}`
          )
          .open()

        await f7.views.main.router.navigate('/sorting/entry/')
        return
      }

      await onConfirmToStartSortProcess(pickBatch.id, pickBatchSortState)
    } catch (e) {
      await perceptibleToast.error(e.message)
      throw e
    }
  }

  const onConfirmToStartSortProcess = async (pickBatchId: ID) => {
    await soundBoard.playSuccessSound()

    await confirmYesNo({
      title: `Are you sure that you want to start the sort process for pick batch "${pickBatchId}"?`,
      yesButtonCallback: async () => {
        await addProject({ pickBatchId })
        await nextTick(() =>
          f7.views.main.router.navigate(`/sorting/${pickBatchId}/scan-pick-load-carrier/`)
        )
      }
    })
  }

  const composeCompartmentName = (compartmentReference: ICompartmentReference) =>
    `${compartmentReference.rowReference}-${compartmentReference.compartmentReference}`

  const calculateStateClass = (value: boolean) => {
    if (value) {
      return 'text-color-green'
    }
    return 'color-text-gray-light'
  }

  return {
    onResult,
    getSequenceFor,
    putArticleInSortCompartment,
    wantsNewCompartment,
    composeCompartmentName,
    getSortResult,
    getLoadCarrierByReference,
    getPickBatchSortState,
    calculateStateClass,
    markSortLoadCarrierSorted,
    markPickBatchSorted,
    getBulkSortableItems,
    bulkSort
  }
}
