import { useStore } from '@store/store'
import { IWarehouse } from '@graphql/warehouse/types'

export default function () {
  const store = useStore()
  const getList = (): Promise<IWarehouse[]> => store.dispatch(`warehouse/getList`)

  return {
    getList
  }
}
