import { ID, IExtractedOperationNameAndQuery, IGraphQLResponse } from '@graphql/types'
import { AxiosHeaders, AxiosInstance, AxiosRequestHeaders, AxiosResponse } from 'axios'
import { DocumentNode } from 'graphql/language'

class Base {
  private _authToken = ''
  private _deviceId: ID | null = null

  // eslint-disable-next-line no-useless-constructor
  constructor(
    protected axios: AxiosInstance,
    private path: string
  ) {}

  public async getOperationDetails(
    operationFilePath: string
  ): Promise<IExtractedOperationNameAndQuery> {
    const importedQueryFile = await import(`./${this.path}/${operationFilePath}.graphql`)

    const documentNode: DocumentNode = importedQueryFile.default
    const operation = documentNode.definitions.find(
      (definition) => definition.kind === 'OperationDefinition'
    )

    let operationName = ''
    if (operation && 'name' in operation && operation.name) {
      operationName = operation.name.value
    }

    let selectionName = ''
    if (!operationName) {
      const firstSelection = operation.selectionSet.selections[0]
      if (firstSelection && 'name' in firstSelection && firstSelection.name) {
        selectionName = firstSelection.name.value
      }
    }

    if (selectionName != '' && selectionName != operationName) {
      operationName = selectionName
    }

    const query = documentNode.loc?.source.body ?? ''

    return { operationName, query }
  }

  public async get<T extends IGraphQLResponse>(
    operationFilePath: string,
    variables: unknown = {}
  ): Promise<AxiosResponse<T>> {
    const { query, operationName } = await this.getOperationDetails(operationFilePath)
    const response = await this.axios.get<T>(this.makeRequestUrl(query, variables, operationName))

    await this.handleGraphQLErrors(response)

    return response
  }

  public async makeRequest<T extends IGraphQLResponse>(
    operationFilePath: string,
    variables: unknown = {},
    requiredAuth = true
  ): Promise<AxiosResponse<T>> {
    const { query, operationName } = await this.getOperationDetails(operationFilePath)

    const headers: AxiosRequestHeaders = <AxiosHeaders>{}
    if (requiredAuth) {
      headers['Sp-Auth-Token'] = this.authToken
    }

    if (this.deviceId) {
      headers['Sp-Device-Id'] = this.deviceId
    }

    const endpoint = this.endpoint
    // + '?XDEBUG_SESSION_START=PHPSTORM' //uncomment for debugging in backend

    const response = await this.axios.post<T>(
      endpoint,
      { query, variables, operationName },
      {
        headers: headers
      }
    )

    await this.handleGraphQLErrors(response)

    if (response?.data?.data[operationName] === undefined) {
      throw new Error('Whoops!!! GraphQL operation data missing!')
    }

    return response
  }

  public makeRequestUrl(query: string, variables: unknown, operationName: string): string {
    const params = new URLSearchParams()
    params.append('sp-auth-token', this.authToken)
    params.append('sp-device-id', this.deviceId ?? '')
    params.append('operationName', operationName)
    // params.append('XDEBUG_SESSION_START', 'PHPSTORM')

    return `${this.endpoint}?${params.toString()}&variables=${encodeURIComponent(
      JSON.stringify(variables)
    )}`
  }

  private async handleGraphQLErrors(response: AxiosResponse<IGraphQLResponse>): Promise<void> {
    if (response && response.status === 200 && 'errors' in response.data) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      throw Error(response.data.errors[0].message)
    }
  }

  get endpoint(): string {
    return `/${this.path}`
  }

  get authToken(): string {
    return this._authToken
  }

  set authToken(token: string) {
    this._authToken = token
  }

  get deviceId(): ID | null {
    return this._deviceId
  }

  set deviceId(deviceId: ID | null) {
    this._deviceId = deviceId
  }

  get tokenParam(): string {
    return `Sp-Auth-Token=${this.authToken}`
  }
}

export default Base
