import { Router } from 'framework7/types'
import { store } from '@store/store'
import { computed } from 'vue'
import { ISortingProject } from '@store/modules/projects/sorting/types'
import { IGetPickBatchSortStateRequest, IPickBatchSortState } from '@graphql/sort/types'
import { perceptibleNotification } from '@services/perceptibleNotification'
import { captureException } from '@sentry/vue'

const findRedirectUrlForProject = async (
  project: ISortingProject,
  pickBatchSortState: IPickBatchSortState,
  resolve: Function
) => {
  if (pickBatchSortState.pickLoadCarriersSorted && !pickBatchSortState.sortLoadCarriersSorted) {
    return resolve(`/sorting/${project.pickBatchId}/print-documents/`)
  }

  let lastPickLoadCarrierId = pickBatchSortState.lastSortReference.pickLoadCarrierId

  if (!lastPickLoadCarrierId && pickBatchSortState.pickLoadCarrierReferences.length > 0) {
    lastPickLoadCarrierId =
      pickBatchSortState.pickLoadCarrierReferences[
        pickBatchSortState.pickLoadCarrierReferences.length - 1
      ].identifier
  }

  if (lastPickLoadCarrierId) {
    const activePickLoadCarrier = pickBatchSortState.pickLoadCarrierReferences.find(
      (reference) => reference.identifier === lastPickLoadCarrierId && !reference.sorted
    )

    if (activePickLoadCarrier) {
      let sortLoadCarrierId = pickBatchSortState.lastSortReference.sortLoadCarrierId

      if (!sortLoadCarrierId && pickBatchSortState.sortLoadCarrierReferences.length > 0) {
        sortLoadCarrierId =
          pickBatchSortState.sortLoadCarrierReferences[
            pickBatchSortState.sortLoadCarrierReferences.length - 1
          ].identifier
      }

      if (pickBatchSortState.isBulkSortEnabled && !project.isBulkSortCompleted) {
        sortLoadCarrierId =
          pickBatchSortState.sortLoadCarrierReferences[
            pickBatchSortState.sortLoadCarrierReferences.length - 1
          ].identifier

        return resolve(`/sorting/${lastPickLoadCarrierId}/bulk/process/${sortLoadCarrierId}/`, {
          reloadAll: true
        })
      }

      if (sortLoadCarrierId) {
        return resolve(`/sorting/${lastPickLoadCarrierId}/process/${sortLoadCarrierId}/`)
      }

      return resolve(`/sorting/${lastPickLoadCarrierId}/scan-sort-load-carrier/`)
    }
  }

  if (pickBatchSortState.sortLoadCarriersSorted) {
    return resolve(`/sorting/${project.pickBatchId}/print-documents/`)
  }

  return resolve(`/sorting/${project.pickBatchId}/scan-pick-load-carrier/`)
}

export const redirectMiddleware = async ({ resolve }: Router.RouteCallbackCtx) => {
  const getPickBatchSortState = async (
    request: IGetPickBatchSortStateRequest
  ): Promise<IPickBatchSortState> => store.dispatch('sort/getPickBatchSortState', request)

  const activeItem = computed<ISortingProject[]>(() => store.getters['projects/sorting/activeItem'])

  if (activeItem.value) {
    try {
      const pickBatchSortState = await getPickBatchSortState({
        pickBatchId: activeItem.value.pickBatchId
      })

      if (pickBatchSortState.sortLoadCarriersSorted) {
        await perceptibleNotification.finishedTask(
          `Pick batch #${activeItem.value?.pickBatchId} is already sorted or completed elsewhere!`
        )
        await store.dispatch('projects/sorting/removeProject', activeItem.value?.pickBatchId)
        await resolve('/sorting/')
        return
      }

      await findRedirectUrlForProject(activeItem.value, pickBatchSortState, resolve)
      return
    } catch (e) {
      await perceptibleNotification.finishedTask(
        `An error occurred while retrieving the pick batch sort state: ${e.message}`
      )
      await store.dispatch('projects/sorting/removeProject', activeItem.value?.pickBatchId)
      captureException(e)
      await resolve('/sorting/')
    }
    return
  }

  await resolve('/sorting/')
}
