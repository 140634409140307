import {
  IBulkPrintDocumentResponse,
  IBulkPrintDocumentsRequest,
  IFinalizePackingControlRequest,
  IGetColloByIdRequest,
  IGetShipmentByIdRequest,
  IPrintDocumentsRequest,
  IPrintDocumentsResponse,
  IShipment
} from '@graphql/shipment/types'
import { useStore } from '@store/store'
import { AxiosResponse } from 'axios'
import { computed } from 'vue'
import { ID } from '@graphql/types'
import { TypeName } from '@graphql/search/types'
import { f7 } from 'framework7-vue'
import { DocumentKind } from '@graphql/document/types'

export default () => {
  const store = useStore()

  const shipment = computed<IShipment>(() => store.getters[`shipment/shipment`])

  const setShipment = async (shipment: IShipment): Promise<Partial<IShipment>> =>
    store.dispatch('shipment/setShipment', shipment)

  const getShipmentById = async (request: IGetShipmentByIdRequest) =>
    store.dispatch(`shipment/getShipmentById`, request)
  const finalizePackingControl = async (request: IFinalizePackingControlRequest) =>
    store.dispatch(`shipment/finalizePackingControl`, request)
  const printDocuments = async (
    request: IPrintDocumentsRequest
  ): Promise<AxiosResponse<IPrintDocumentsResponse>> =>
    store.dispatch(`shipment/printDocuments`, request)
  const bulkPrintDocuments = async (
    request: IBulkPrintDocumentsRequest
  ): Promise<AxiosResponse<IBulkPrintDocumentResponse>> =>
    store.dispatch(`shipment/bulkPrintDocuments`, request)

  const getColloById = async (request: IGetColloByIdRequest) =>
    store.dispatch('shipment/getColloById', request)

  const printShippingLabelForCollo = async (colloId: ID, packStationId: ID) => {
    await printDocuments({
      packStationId,
      context: {
        identifier: colloId,
        type: TypeName.Collo,
        documentKind: DocumentKind.SHIPPING_LABELS
      }
    })
  }

  const printPackingAttachmentsForCollo = async (colloId: ID, packStationId: ID) => {
    await printDocuments({
      packStationId,
      context: {
        identifier: colloId,
        type: TypeName.Collo,
        documentKind: DocumentKind.PACKING_ATTACHMENTS
      }
    })
  }

  const onNavigateToShipment = async (shipment: IShipment) => {
    await f7.views.main.router.navigate(`/shipment/${shipment.id}/`)
  }

  const onNavigateToCollo = async (collo: IColli) => {
    await f7.views.main.router.navigate(`/collo/${collo.id}/`)
  }

  return {
    shipment,
    setShipment,
    getShipmentById,
    finalizePackingControl,
    printDocuments,
    bulkPrintDocuments,
    getColloById,
    printShippingLabelForCollo,
    printPackingAttachmentsForCollo,
    onNavigateToShipment,
    onNavigateToCollo
  }
}
