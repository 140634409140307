<template>
  <div class="compartments-container">
    <div
      class="compartments"
      :class="large ? 'large' : null"
      v-for="(compartments, index) in availableSeries"
      :key="compartments.startCompartment"
    >
      <div class="compartment" :class="`sequence-${index + 1}`">
        {{ compartments.startCompartment }}
      </div>
      <font-awesome-icon :icon="['far', 'arrow-right']" />
      <div class="compartment" :class="`sequence-${index + 1}`">
        {{ compartments.endCompartment }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ICompartmentSeries } from '@graphql/sort/types'
import { computed } from 'vue'

const availableSeries = computed<ICompartmentSeries[]>(() => {
  const excludedStartCompartments = props.excludedSeries.map((series) => series.startCompartment)

  return props.compartmentSeries.filter(
    (series) => !excludedStartCompartments.includes(series.startCompartment)
  )
})

const props = withDefaults(
  defineProps<{
    compartmentSeries: ICompartmentSeries[]
    excludedSeries?: ICompartmentSeries[]
    large?: boolean
  }>(),
  {
    excludedSeries: () => [],
    large: false
  }
)
</script>

<style scoped lang="less">
.compartments-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .compartments {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .compartment {
      background: var(--f7-color-secondary);
      color: white;
      padding: 5px;
      aspect-ratio: 1/1;
      display: grid;
      place-items: center;
      border-radius: 5px;
      font-size: 16px;
      font-weight: bold;

      &.sequence-1 {
        background-color: #004488;
        color: #fff;
      }

      &.sequence-2 {
        background-color: #e69f00;
        color: #000;
      }

      &.sequence-3 {
        background-color: #009e73;
        color: #fff;
      }

      &.sequence-4 {
        background-color: #f0e442;
        color: #000;
      }

      &.sequence-5 {
        background-color: #cc79a7;
        color: #000;
      }
    }

    &.large {
      .compartment {
        padding: calc(5px * 4);
        font-size: calc(16px * 4);
      }
    }
  }
}
</style>
