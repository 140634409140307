<template>
  <f7-page>
    <navigation hide-back-button title="Bulk sort" />

    <f7-card>
      <f7-card-header>Start bulk sort</f7-card-header>
      <f7-card-content>
        <icon-with-text
          :icon="['far', 'barcode-scan']"
          title="Scan article"
          description="Scan a barcode of an article to start bulk sorting"
        />
      </f7-card-content>
    </f7-card>

    <f7-card>
      <f7-card-header>Items</f7-card-header>
      <f7-list>
        <f7-list-item v-if="isLoading">
          <template #inner>
            <div class="loader">
              <f7-preloader text-color="dimass" />
            </div>
          </template>
        </f7-list-item>

        <template v-if="!isLoading">
          <f7-list-item v-for="item in bulkSortableItems" :key="item.article.id">
            <bulk-sortable-item :item="item" />
          </f7-list-item>
        </template>
      </f7-list>
    </f7-card>
    <f7-button @click="onSkip">Skip</f7-button>

    <f7-popup v-if="selectedItem" :opened="selectedItemPopupOpened" @popup:closed="onPopupClosed">
      <f7-page>
        <f7-navbar>
          <f7-nav-right>
            <f7-link @click="onPopupClosed">
              <f7-icon material="close" />
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-card>
          <f7-card-header> Article </f7-card-header>
          <f7-card-content>
            <bulk-sortable-item :item="selectedItem" hide-compartment-series />
          </f7-card-content>
        </f7-card>
        <f7-card>
          <f7-card-header> Scan compartments </f7-card-header>
          <f7-card-content>
            <div class="sort-card-content">
              <compartment-series
                large
                :compartment-series="selectedItem.compartmentSeries"
                :excluded-series="completedSeries"
              />
              <div class="title">Scan compartments</div>
              <div class="description">Scan first and last compartment to confirm</div>
            </div>
          </f7-card-content>
        </f7-card>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<script lang="ts" setup>
import Navigation from '@components/AppNavigation.vue'
import useProjectSorting from '@composables/useProjectSorting'
import { ID } from '@graphql/types'
import { f7 } from 'framework7-vue'
import { confirmYesNo } from '@/functions/dialog'
import { onMounted, onUnmounted, ref } from 'vue'
import useLoading from '@composables/useLoading'
import useSort from '@composables/useSort'
import { IBulkSortableItem, ICompartmentSeries } from '@graphql/sort/types'
import BulkSortableItem from '@pages/sorting/components/BulkSortableItem.vue'
import IconWithText from '@components/IconWithText.vue'
import { TypeName } from '@graphql/search/types'
import search, { SearchStrategy } from '@services/search/search'
import { IArticle } from '@graphql/article/types'
import { perceptibleToast } from '@services/perceptibleToast'
import { eventBus, IEvent } from '@/utilities/scanInput'
import { soundBoard } from '@services/sound'
import CompartmentSeries from '@pages/sorting/components/CompartmentSeries.vue'

const props = defineProps<{
  pickLoadCarrierId: ID
  sortLoadCarrierId: ID
}>()

const bulkSortableItems = ref<IBulkSortableItem[]>([])
const selectedItem = ref<IBulkSortableItem | null>(null)
const selectedItemPopupOpened = ref<boolean>(false)
const selectedSeries = ref<ICompartmentSeries | null>(null)
const completedSeries = ref<ICompartmentSeries[]>([])
const startCompartment = ref<string | null>(null)
const endCompartment = ref<string | null>(null)

const { withAsyncLoading, isLoading } = useLoading()
const { activeItem, updateProject } = useProjectSorting()
const { getBulkSortableItems, bulkSort } = useSort()

onMounted(async () => {
  await withAsyncLoading(async () => {
    bulkSortableItems.value = await getBulkSortableItems({
      pickLoadCarrierId: props.pickLoadCarrierId,
      sortLoadCarrierId: props.sortLoadCarrierId
    })
  })
})

const unsubscribe = eventBus.on('scanInput', async (event: IEvent) => {
  const query = String(event.payload)

  if (query.match(/^\d{1,3}-[0-9A-Z]{3}-[A-Z]-\d{2}$/) && selectedItemPopupOpened.value) {
    await onCompartmentScan(query)
    return
  }

  if (selectedItemPopupOpened.value) {
    return
  }

  const response = await search
    .getStrategy(SearchStrategy.DefaultWithoutRouting)
    .search(query, true, TypeName.Article, [])

  if (response.search.length === 0) {
    await perceptibleToast.error('Scan a valid article.')
    return
  }

  await onArticleScan(response.search[0])
})

onUnmounted(() => {
  unsubscribe()
})

const onCompartmentScan = async (query: string) => {
  const compartment = query.split('-').slice(-2).join('-')

  if (!selectedItem.value) {
    return
  }

  const compartmentSeries = selectedItem.value.compartmentSeries.find(
    (series) => series.startCompartment === compartment || series.endCompartment === compartment
  )

  if (startCompartment.value === null && compartmentSeries?.startCompartment !== compartment) {
    await perceptibleToast.error('Scan the first valid compartment.')
    return
  }

  if (startCompartment.value === null) {
    selectedSeries.value = compartmentSeries
    startCompartment.value = query
    await soundBoard.playSearchSingleHit()
    return
  }

  if (compartment !== selectedSeries.value.endCompartment) {
    await perceptibleToast.error('Scan the last valid compartment.')
    return
  }

  endCompartment.value = query
  await soundBoard.playSearchSingleHit()

  await onConfirm()
  selectedSeries.value = null
  startCompartment.value = null
  endCompartment.value = null

  if (completedSeries.value.length !== selectedItem.value?.compartmentSeries.length) {
    return
  }

  onPopupClosed()
  await navigateToNextStep()
}

const onConfirm = async () => {
  try {
    await bulkSort({
      sortLoadCarrierId: props.sortLoadCarrierId,
      articleId: selectedItem.value?.article.id,
      startCompartment: startCompartment.value,
      endCompartment: endCompartment.value,
      compartmentTypes: selectedSeries.value?.types
    })

    const currentCompletedSeries = completedSeries.value
    currentCompletedSeries.push(selectedSeries.value)
    completedSeries.value = currentCompletedSeries
  } catch (e) {
    await perceptibleToast.error(e.message)
  }
}

const onArticleScan = async (result: IArticle) => {
  selectedItem.value = bulkSortableItems.value.find(
    (item: IBulkSortableItem) => item.article.id === result.id
  )
  selectedItemPopupOpened.value = true
}

const onPopupClosed = () => {
  selectedItem.value = null
  selectedItemPopupOpened.value = false
}

const onSkip = async () => {
  confirmYesNo({
    title: `Are you sure that you want to skip bulk sort?`,
    yesButtonCallback: async () => {
      navigateToNextStep()
    }
  })
}

const navigateToNextStep = async () => {
  if (!activeItem.value) {
    return
  }

  activeItem.value.isBulkSortCompleted = true
  await updateProject(activeItem.value)

  f7.views.main.router.navigate(`/sorting/entry/`, {
    reloadCurrent: true
  })
}
</script>

<style scoped lang="less">
.loader {
  width: 100%;
  display: grid;
  place-items: center;
}

.sort-card-content {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .title {
    padding-top: 15px;
    font-weight: 200;
    text-align: center;
    font-size: 1.6em;
  }

  .description {
    text-align: center;
  }
}
</style>
