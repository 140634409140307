<template>
  <load-carrier-scan-to-print
    :pick-batch-id="pickBatchId"
    :verification-popup-opened="verificationPopupOpened"
    :printer-code="activeItem?.printerCode"
    @print-documents="onPrintDocuments"
    @confirm-barcode="onConfirmBarcode"
    @verification-popup-closed="verificationPopupOpened = false"
  >
    <template #page-title>Finalize sort load carrier(s) for pick batch #{{ pickBatchId }}</template>
    <template #page-content>
      <icon-with-text
        :icon="['far', 'print']"
        title="Scan a nearby label printer"
        description="Print the labels to finalize the sorted items for this pick batch. And place each label on the corresponding load carrier."
      />
    </template>
  </load-carrier-scan-to-print>
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import LoadCarrierScanToPrint from '@components/LoadCarrierScanToPrint.vue'
import IconWithText from '@components/IconWithText.vue'
import useLoading from '@composables/useLoading'
import { ID } from '@graphql/types'
import useSort from '@composables/useSort'
import { f7 } from 'framework7-vue'
import useDocument from '@composables/useDocument'
import { DocumentKind, PrintContext } from '@graphql/document/types'
import { TypeName } from '@graphql/search/types'
import useProjectSorting from '@composables/useProjectSorting'
import { perceptibleToast } from '@services/perceptibleToast'

const props = withDefaults(
  defineProps<{
    pickBatchId: ID
    sortLoadCarrierId: ID
    confirmBarcode: boolean
  }>(),
  {
    confirmBarcode: true
  }
)

const { withAsyncLoading, isLoading } = useLoading()
const { markSortLoadCarrierSorted, getPickBatchSortState, markPickBatchSorted } = useSort()
const { printDocumentsFor } = useDocument()
const { activeItem, updateProject } = useProjectSorting()

const isPrinting = ref(false)
const verificationPopupOpened = ref<boolean>(false)

async function updatePrinterCode(printerCode: string) {
  if (activeItem.value?.printerCode !== printerCode) {
    activeItem.value.printerCode = printerCode
    await updateProject(activeItem.value)
  }
}

const onPrintDocuments = async (printerCode: string) => {
  await updatePrinterCode(printerCode)

  if (isPrinting.value) {
    return
  }

  isPrinting.value = true
  try {
    await withAsyncLoading(async () => {
      await printDocumentsFor(
        new PrintContext(
          props.sortLoadCarrierId,
          TypeName.LoadCarrier,
          DocumentKind.SORT_LABELS,
          printerCode
        )
      )

      if (!props.confirmBarcode) {
        await perceptibleToast.success(`Document sent to printer ${printerCode}!`)

        await f7.views.main.router.navigate(`/sorting/${props.pickBatchId}/print-documents/`)
        return
      }
      verificationPopupOpened.value = true
    })
    isPrinting.value = false
  } catch (e) {
    isPrinting.value = false
    throw e
  }
}

const onConfirmBarcode = async (barcode: string) => {
  try {
    await markSortLoadCarrierSorted({
      sortLoadCarrierId: props.sortLoadCarrierId,
      verificationCode: barcode
    })

    const pickBatchSortState = await getPickBatchSortState({ pickBatchId: props.pickBatchId })

    verificationPopupOpened.value = false

    if (pickBatchSortState.pickLoadCarriersSorted && pickBatchSortState.sortLoadCarriersSorted) {
      try {
        await markPickBatchSorted({ pickBatchId: props.pickBatchId })

        await perceptibleToast.finishedTask(
          `All sort load carrier(s) for pick batch #${props.pickBatchId} are completed!`
        )

        await f7.views.main.router.navigate(`/sorting/${props.pickBatchId}/print-documents/`)

        return
      } catch (e) {
        await perceptibleToast.error(e.message)
        throw e
      }
    }

    await perceptibleToast.success(`Sort load carrier ${props.sortLoadCarrierId} completed!`)

    await f7.views.main.router.navigate('/sorting/entry/')
  } catch (e) {
    await perceptibleToast.error(e.message)
    isLoading.value = false
    throw e
  }
}

onMounted(async () => {
  if (activeItem.value?.printerCode && !verificationPopupOpened.value) {
    await onPrintDocuments(activeItem.value?.printerCode)
  }
})
</script>
