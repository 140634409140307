<template>
  <div v-for="(shownArticleBarcode, index) in shownArticleBarcodes" :key="shownArticleBarcode.id">
    {{ shownArticleBarcode.content }}

    <f7-button
      color="dimass"
      text-color="secondary"
      class="more-barcodes-button"
      small
      v-if="hiddenArticleBarcodes.length > 0 && index === shownArticleBarcodes.length - 1"
      @click="toggleHiddenArticleBarcodes"
    >
      +{{ hiddenArticleBarcodes.length }}
    </f7-button>
  </div>
  <template v-if="showHiddenArticleBarcodes">
    <div v-for="hiddenArticleBarcode in hiddenArticleBarcodes" :key="hiddenArticleBarcode.id">
      {{ hiddenArticleBarcode.content }}
    </div>
  </template>

</template>
<script setup lang="ts">
import { IArticleBarcode } from '@graphql/article/types'
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    articleBarcodes: IArticleBarcode[]
    primaryBarcode: string
  }>(),
  {
    primaryBarcode: '#NOBARCODE',
    articleBarcodes: () => [
      {
        content: '#NOBARCODE'
      }
    ]
  }
)

const showHiddenArticleBarcodes = ref(false)
const shownBarcodeCount = 2

const sortedArticleBarcodes = computed<IArticleBarcode[]>(() => {
  const articleBarcodes = [...props.articleBarcodes]
  const primaryBarcodeIndex = articleBarcodes.findIndex(
    (articleBarcode) => articleBarcode.content === props.primaryBarcode
  )

  let primaryBarcode = { content: props.primaryBarcode }
  if (primaryBarcodeIndex > -1) {
    primaryBarcode = articleBarcodes[primaryBarcodeIndex]

    articleBarcodes.splice(primaryBarcodeIndex, 1)
  }
  return [primaryBarcode, ...articleBarcodes]
})

const shownArticleBarcodes = computed<IArticleBarcode[]>(() => {
  let articleBarcodes = [...sortedArticleBarcodes.value]

  return articleBarcodes.slice(0, shownBarcodeCount)
})

const hiddenArticleBarcodes = computed<IArticleBarcode[]>(() => {
  let articleBarcodes = [...sortedArticleBarcodes.value]

  return articleBarcodes.slice(shownBarcodeCount)
})

const toggleHiddenArticleBarcodes = () =>
  (showHiddenArticleBarcodes.value = !showHiddenArticleBarcodes.value)
</script>
<style scoped>
.more-barcodes-button {
  display: inline-block;
  margin-left: 10px;
}
</style>
