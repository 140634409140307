<template>
  <f7-navbar>
    <f7-nav-left :back-link="!hideBackButton" />
    <slot name="page-title">
      <f7-nav-title v-if="title">{{ title }}</f7-nav-title>
    </slot>
    <f7-nav-right>
      <f7-link
        v-if="showSearchIcon"
        class="searchbar-enable"
        :data-searchbar="searchClassName"
        icon-ios="f7:search"
        icon-aurora="f7:search"
        icon-md="material:search"
      >
      </f7-link>

      <f7-link @click="goHome" icon-only v-if="!hideHomeButton">
        <f7-icon material="home" />
      </f7-link>
      <f7-link panel-open="right" icon-only v-if="!hideMenu">
        <f7-icon material="apps">
          <f7-badge color="red" v-if="hasActiveProjects">
            {{ activeProjectCount }}
          </f7-badge>
        </f7-icon>
      </f7-link>
    </f7-nav-right>

    <slot :search-id="searchId" />
  </f7-navbar>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { f7 } from 'framework7-vue'
import useProjects from '@composables/useProjects'

withDefaults(
  defineProps<{
    showSearchIcon?: boolean
    hideBackButton?: boolean
    hideHomeButton?: boolean
    hideMenu?: boolean
    title?: string
  }>(),
  {
    showSearchIcon: false,
    hideBackButton: false,
    hideHomeButton: false,
    hideMenu: false,
    title: null
  }
)

const { hasActiveProjects, activeProjectCount } = useProjects()
const searchId = computed<string>(() => 'search' + Math.random().toString(36).substring(2, 15))
const searchClassName = computed<string>(() => '.' + searchId.value)

const goHome = async (): Promise<void> => {
  f7.views.main.router.navigate('/home/', { reloadAll: true })
}
</script>
<style lang="less">
.navbar {
  background: var(--f7-theme-color);
  color: var(--f7-text-color);
}

.navbar-bg {
  background: var(--f7-theme-color);
  color: var(--f7-text-color);
}

.no-padding.subnavbar > .subnavbar-inner {
  padding: 0;
}
</style>
